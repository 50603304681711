<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar active="myprofile" :user="body.userName" :image="body.image" />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile myproffsf mt-5 pt-4">
          <div class="header-profile">
            <h2>{{ $t("Modify the mobile number") }}</h2>
          </div>
          <div class="col-lg-6 col-md-12 from-all">
            <Card>
              <template #content>
                <form @submit.prevent="save()">
                  <div class="text-center mb-5">
                    <img
                      class="from-img"
                      v-if="body.image"
                      :src="$baseUploadURL + body.image"
                    />
                    <img
                      class="from-img"
                      v-else
                      src="/images/user.png"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("phone") }}</label
                    >
                    <vue-tel-input
                      placeholder="01000000000 "
                      v-model="body.phone"
                      v-bind="{
                        mode: 'international',
                      }"
                      style="direction: initial"
                    >
                    
                    </vue-tel-input>
                  </div>

                  <div class="mb-5 pt-5">
                    <button
                      type="submit"
                      :disabled="disabledAdd"
                      class="btn-editprofile btn btn-outline-success btn-block"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </form>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        image: null,
      },
      isInvalid: false,
      disabledAdd: false,
      id: null,
    };
  },

  methods: {
    save() {
      if (this.body.phone && this.disabledAdd == false) {
        this.disabledAdd = true;

        delete this.body.image;

        this.$http
          .put("users/" + this.id, {
            phone: this.body.phone,
          })
          .then(
            (res) => {
              this.body.image = res.data.image;

              this.disabledAdd = false;
              this.$router.push("/myprofile");
              this.$toast.add({
                severity: "success",
                summary: this.$t("Done Successfully"),
                detail: this.$t("Updated Successfully"),
                life: 3000,
              });
            },
            (err) => {
              this.disabledAdd = false;

              this.$toast.add({
                severity: "error",
                summary: this.$t("Error"),
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: this.$t("Please add the required fields"),
          life: 3000,
        });
      }
    },
  },
  async created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      const body = res.data;
      this.body.userName = body.userName;
      this.body.phone = body.phone;
      this.body.image = body.image;
      this.id = body._id;
    });
  },
};
</script>
<style>

</style>